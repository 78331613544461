import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Grid, DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';

import { Formik } from 'formik';
import * as Yup from 'yup';
import moment from 'moment-timezone';

import { Form } from '../Common/styled/Form';
import useStyles from './styled/PatientFormMakeStyles';
import { patientGenderList } from '../../utils/patientGenderList';
import { countryList } from '../../../locales/countries';
import { getLocaleSortedSelectList } from '../../utils/SelectListUtils';
import { TextFieldUI } from '../../componentsUI/TextField';
import { TextFieldSelectUI } from '../../componentsUI/TextFieldSelect';
import { DateTimeFieldUI } from '../../componentsUI/DateTimeField';
import Loading from '../Common/Loading';
import { parseGraphqlErrors } from '../../utils/FormikUtils';
import { AlertUI } from '../../componentsUI/Alert';

export const PatientForm = ({
  initialValues,
  submitting,
  submitAction,
  submitLabel,
  serverError,
  setServerError,
  onClose,
  formName = 'patient-form-edit',
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const selectCountryList = getLocaleSortedSelectList(countryList);
  const today = moment.tz().format('YYYY-MM-DD');
  const minDate = new Date('1900-01-01');
  const [updating, setUpdating] = useState(false);

  const validationSchema = Yup.object().shape({
    customId: Yup.string()
      .min('5', t('id.too.short'))
      .required(t('required.field')),
    email: Yup.string()
      .email(t('invalid.email')).nullable(),
    name: Yup.string()
      // .matches(/^[A-zÀ-ú\s]+$/, t('only.fade'))
      .required(t('required.field')),
    surname: Yup.string()
      // .matches(/^[A-zÀ-ú\s]+$/, t('only.fade'))
      .required(t('required.field')),
    birthDate: Yup.date()
      .required(t('required.field'))
      .min(minDate, t('birth.date.too.old'))
      .max(today, t('birth.date.before.today')),
    identityNumber: Yup.string().nullable(),
    nationality: Yup.string().nullable(),
    insurance: Yup.string().nullable(),
    insuranceNumber: Yup.string().nullable(),
    phone: Yup.string().nullable(),
    address: Yup.string().nullable(),
    gender: Yup.string().nullable(),
  });

  const handleSubmit = async (form, { setErrors, resetForm }) => {
    setServerError(false);
    setUpdating(true);
    try {
      await submitAction(form);
      setTimeout(() => {
        resetForm({});
        setUpdating(false);
      }, 3000);
    } catch (errors) {
      const formikErrors = parseGraphqlErrors(errors.graphQLErrors, t);
      if (
        Object.keys(formikErrors).length === 0
        && formikErrors.constructor === Object
      ) {
        setServerError(true);
      } else {
        setErrors(formikErrors);
      }
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form autoComplete="off" id={formName}>
          <Grid container spacing={2} className={classes.form}>
            <Grid item xs={12} sm={6}>
              <Grid container item xs={12}>
                <TextFieldUI name="customId" label={t('patient.id')} props={props} classes={classes} />
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="name" label={t('name')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="surname" label={t('surname')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DateTimeFieldUI
                name="birthDate"
                minDate={minDate}
                maxDate={today}
                iconPosition="right"
                label={t('birth.date')}
                props={props}
                classes={classes}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="email" label={t('email')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="identityNumber" label={t('identity.number')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldSelectUI
                name="nationality"
                label={t('nationality')}
                props={props}
                options={selectCountryList}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="insurance" label={t('insurance')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="insuranceNumber" label={t('insurance.number')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI type="phone" name="phone" label={t('phone')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldUI name="address" label={t('address')} props={props} classes={classes} />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextFieldSelectUI
                name="gender"
                label={t('gender')}
                props={props}
                options={patientGenderList}
              />
            </Grid>
            <Grid item xs={12}>
              {submitting && !serverError && <Loading />}
              {!!serverError && (
                <AlertUI severity="error" title="Error">
                  {t('server.error')}
                </AlertUI>
              )}
            </Grid>
          </Grid>
          <DialogActions>
            <Button
              variant="outlined"
              className={classes.button}
              disabled={submitting || updating}
              color="primary"
              onClick={onClose}
            >
              {t('cancel')}
            </Button>
            <Button
              variant="contained"
              className={classes.button}
              disabled={submitting || updating}
              type="submit"
              form={formName}
              color="primary"
            >
              {submitLabel}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
